<template lang="pug">
.smart-headline
  .smart-headline-title.d-flex
    span {{ $t('smartHeadline.title') }}
  om-button.smart-headline-generate-button(
    @click="generateSuggestions"
    :loading="loading"
    :disabled="generationDisabled"
    primary
  )
    generate-smart-headline-svg.mr-1(:color="generationDisabled ? '#8f97a4' : 'white'")
    | {{ $t('smartHeadline.generateSuggestions') }}
  .smart-headline-description {{ limitReached ? $t('smartHeadline.limitReached') : $t('smartHeadline.description') }}
  .smart-headline-suggestions(v-if="suggestions.length")
    .smart-headline-suggestions-title {{ $t('smartHeadline.suggestions') }}
    .smart-headline-suggestion-box(
      v-for="{ choice } in suggestions"
      :class="{ disabled: applyDisabled }"
      @click="applySuggestion(choice)"
    )
      .smart-headline-suggestion-box-content {{ choice }}
      img.applied-chip(src="@/assets/editor/svg/chip-tick.svg" v-if="choice === currentText")
    om-button.smart-headline-reset-button(
      :style="{ color: originalText ? '#505763 !important' : '#B9BEC6 !important' }"
      v-tooltip="$t('smartHeadline.resetTooltip')"
      :disabled="!originalText"
      @click="reset"
      ghost
      small
    ) {{ $t('smartHeadline.reset') }}
</template>
<script>
  import { mapState } from 'vuex';
  import { track } from '@/services/xray';
  import GET_SMART_HEADLINE_SUGGESTIONS from '@/graphql/GetSmartHeadlineSuggestions.gql';
  import GENERATE_SMART_HEADLINE_SUGGESTIONS from '@/graphql/GenerateSmartHeadlineSuggestions.gql';
  import GenerateSmartHeadlineSvg from '@/assets/editor/svg/GenerateSmartHeadline.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { GenerateSmartHeadlineSvg },
    mixins: [itemMixin],
    data: () => ({
      suggestions: [],
      limitReached: false,
      loading: false,
    }),
    computed: {
      ...mapState(['selectedElement', 'campaign']),
      currentText() {
        return new DOMParser().parseFromString(this.selectedElement.data.text, 'text/html')
          .documentElement.textContent;
      },
      generationDisabled() {
        if (this.currentText === this.$t('typeHere') || this.currentText === '') return true;
        if (this.limitReached) return true;
        return false;
      },
      applyDisabled() {
        if (this.currentText === '') return true;
        return false;
      },
      originalText: {
        get() {
          return this.selectedElement.data.originalText;
        },
        set(v) {
          this.setValueOf('selectedElement.data.originalText', v);
        },
      },
    },
    async mounted() {
      const { data } = await this.$apollo.query({
        query: GET_SMART_HEADLINE_SUGGESTIONS,
        variables: {
          variantId: this.$route.params.id,
          elementId: this.selectedElement.uid,
        },
      });

      if (data?.suggestions?.length) {
        this.suggestions = data.suggestions;
      }
    },
    methods: {
      applySuggestion(choice) {
        if (!this.originalText) {
          this.originalText = this.currentText;
        }

        this.replaceTextContent(choice);
      },
      replaceTextContent(text) {
        const element = new DOMParser().parseFromString(
          this.selectedElement.data.text,
          'text/html',
        ).documentElement;

        const content = element.querySelector('.om-dtr-content');
        const firstSpan = content.firstChild;
        firstSpan.innerText = text;
        content.innerHTML = firstSpan.outerHTML;

        this.setValueOf('selectedElement.data.text', content.outerHTML);
        this.$bus.$emit('refreshWysiwyg', this.selectedElement.uid);
      },
      reset() {
        this.replaceTextContent(this.originalText);
        this.originalText = null;

        track('smart-headline-usage', {
          action: 'reset',
          userId: this.$route.params.userId,
          campaignId: this.$route.params.campaignId,
          variantId: this.$route.params.id,
          elementId: this.selectedElement.uid,
        });
      },
      async generateSuggestions() {
        this.loading = true;
        try {
          const userInput = this.originalText || this.currentText;
          let result;
          const url = this.campaign.domain;
          const campaignId = this.$route.params.campaignId;
          const variantId = this.$route.params.id;
          const elementId = this.selectedElement.uid;

          if (url && campaignId && variantId && elementId) {
            const { data } = await this.$apollo.mutate({
              mutation: GENERATE_SMART_HEADLINE_SUGGESTIONS,
              variables: {
                userInput,
                url,
                campaignId,
                variantId,
                elementId,
              },
            });
            result = data.generateSmartHeadline;
          }

          if (!result || result.error) {
            throw new Error(result.error);
          }
          if (result.suggestions) {
            this.suggestions = result.suggestions;
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
          console.error(e);
          let notifyText = this.$t('smartHeadline.unknownError');
          if (e.message === 'limit_reached') {
            notifyText = this.$t('smartHeadline.limitReached');
            this.limitReached = true;
          } else if (e.message === 'no_input') {
            notifyText = this.$t('smartHeadline.noInput');
          }
          this.$notify({
            type: 'error',
            text: notifyText,
          });
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .smart-headline
    &-title
      font-size: 14px
      font-weight: 500
      margin-bottom: 8px
      padding: 12px 0
    &-description
      color: $om-gray-600
      font-size: 12px
      margin-top: 8px
    &-suggestions-title
      color: $om-gray-700
      font-size: 12px
      font-weight: 400
      margin: 12px 0
    &-generate-button
      width: 100%
    &-suggestion-box
      position: relative
      cursor: pointer
      border: 1px solid rgba(227, 229, 232, 1)
      margin-bottom: 8px
      &:hover
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1)
      &-content
        color: $om-gray-700
        font-size: 12px
        line-height: 20px
        padding: 16px
      &.disabled
        opacity: 0.5
        pointer-events: none
  .applied-chip
    position: absolute
    top: -10px
    right: -10px
</style>
